import { dev, version } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_NODE_ENV } from '$env/static/public';

import { processSentryError } from '$utils/error.js';

Sentry.init({
	dsn: 'https://14d99b1981f63f6b59f8c499deef193b@o4505938575097856.ingest.us.sentry.io/4507479326916608',
	tracesSampleRate: dev ? 0 : 1,
	replaysSessionSampleRate: dev ? 0 : 0.1,
	replaysOnErrorSampleRate: dev ? 0 : 1,
	integrations: [
		Sentry.replayIntegration({
			maskAllText: false
		})
	],
	environment: PUBLIC_NODE_ENV,
	version,
	beforeSend: processSentryError({ addUser: false })
});

export const handleError = Sentry.handleErrorWithSentry();
