import { dev } from '$app/environment';
import jwt from 'jsonwebtoken?server';
import * as Sentry from '@sentry/sveltekit';

function processSentryError(opts) {
	return (event, hint) => {
		const err = hint.originalException;
		if (err && !(err instanceof Error) && err.errors) {
			const toSend = new Error(formatDirectusError(err));
			Sentry.captureException(toSend);
			return null;
		}
		if (opts.addUser) {
			const token = event?.request?.cookies?.access_token;
			if (token) {
				const info = jwt.decode(token);
				event.contexts.user = { id: info.id };
			}
		}
		if (dev) {
			console.log('Sentry error');
			console.log(hint.originalException);
			return null;
		} else {
			return event;
		}
	};
}

function formatDirectusError(err) {
	const message = err.errors
		.map((tlErr) => {
			let msg = '- ' + tlErr.message;
			if (tlErr?.extensions?.errors) {
				msg += '\n';
				msg += tlErr.extensions.errors.map((subErr) => '  - ' + subErr.message).join('\n');
			}
			return msg;
		})
		.join('\n');
	return 'Directus error:\n' + message;
}

function error(info) {
	const err = new Error(info);
	err.handleable = true;
	return err;
}

export default error;
export { processSentryError };
